define("discourse/plugins/discourse-zoom/discourse/components/post-top-webinar", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model.topic.webinar}}
    <div class="webinar-banner">
      <Webinar @topic={{@model.topic}} @webinarId={{@model.topic.webinar.id}} />
    </div>
  {{/if}}
  */
  {
    "id": "wIKm4K5K",
    "block": "[[[41,[30,1,[\"topic\",\"webinar\"]],[[[1,\"  \"],[10,0],[14,0,\"webinar-banner\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@topic\",\"@webinarId\"],[[30,1,[\"topic\"]],[30,1,[\"topic\",\"webinar\",\"id\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"if\",\"div\",\"webinar\"]]",
    "moduleName": "discourse/plugins/discourse-zoom/discourse/components/post-top-webinar.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "post-top-webinar"));
});