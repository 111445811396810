define("discourse/plugins/discourse-zoom/discourse/api-initializers/zoom-composer", ["exports", "discourse/helpers/body-class", "discourse/lib/api", "discourse/models/composer", "discourse/widgets/render-glimmer", "discourse/plugins/discourse-zoom/discourse/components/post-top-webinar", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _bodyClass, _api, _composer, _renderGlimmer, _postTopWebinar, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PostBeforeComponent = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <PostTopWebinar @model={{@data.post}} />
    {{#if @data.post.topic.webinar}}
      {{bodyClass "has-webinar"}}
    {{/if}}
  
  */
  {
    "id": "tQmhJn6z",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@model\"],[[30,1,[\"post\"]]]],null],[1,\"\\n\"],[41,[30,1,[\"post\",\"topic\",\"webinar\"]],[[[1,\"    \"],[1,[28,[32,1],[\"has-webinar\"],null]],[1,\"\\n\"]],[]],null]],[\"@data\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-zoom/discourse/api-initializers/zoom-composer.js",
    "scope": () => [_postTopWebinar.default, _bodyClass.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "zoom-composer:PostBeforeComponent"));
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    _composer.default.serializeOnCreate("zoom_id", "zoomId");
    _composer.default.serializeOnCreate("zoom_webinar_title", "zoomWebinarTitle");
    _composer.default.serializeOnCreate("zoom_webinar_start_date", "zoomWebinarStartDate");
    api.decorateWidget("post:before", dec => {
      if (dec.attrs.firstPost && !dec.attrs.cloaked) {
        const post = dec.widget.findAncestorModel();
        return new _renderGlimmer.default(dec.widget, "div.widget-connector", PostBeforeComponent, {
          post
        });
      }
    });
  });
});