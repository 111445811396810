define("discourse/plugins/discourse-zoom/discourse/components/remove-webinar-from-composer", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.zoomId}}
    <div class="composer-webinar">
      <span class="webinar-label">Webinar - </span>
  
      <span class="webinar-title">
        {{this.model.zoomWebinarTitle}}
      </span>
  
      <DButton
        class="cancel no-text"
        @action={{action "removeWebinar"}}
        @icon="xmark"
        @title="zoom.remove"
      />
    </div>
  {{/if}}
  */
  {
    "id": "WYUe+s6P",
    "block": "[[[41,[30,0,[\"model\",\"zoomId\"]],[[[1,\"  \"],[10,0],[14,0,\"composer-webinar\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"webinar-label\"],[12],[1,\"Webinar - \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"webinar-title\"],[12],[1,\"\\n      \"],[1,[30,0,[\"model\",\"zoomWebinarTitle\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"cancel no-text\"]],[[\"@action\",\"@icon\",\"@title\"],[[28,[37,4],[[30,0],\"removeWebinar\"],null],\"xmark\",\"zoom.remove\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"span\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-zoom/discourse/components/remove-webinar-from-composer.hbs",
    "isStrictMode": false
  });
  class RemoveWebinarFromComposer extends _component.default {
    model = null;
    removeWebinar() {
      this.model.set("zoomId", null);
    }
    static #_ = (() => dt7948.n(this.prototype, "removeWebinar", [_object.action]))();
  }
  _exports.default = RemoveWebinarFromComposer;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RemoveWebinarFromComposer);
});